import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripePaymentForm from "./StripePaymentForm";

import { stripePubKey } from "../util/backendUtilities";

const stripeTestPromise = loadStripe(stripePubKey);

const StripeContainer = (props) => {
  const {
    setDidProceed,
    didProceed,
    customerInfo,
    orderTotal,
    clearCart,
    reset,
  } = props;

  return (
    <Elements stripe={stripeTestPromise}>
      <StripePaymentForm
        setDidProceed={setDidProceed}
        didProceed={didProceed}
        customerInfo={customerInfo}
        orderTotal={orderTotal}
        clearCart={clearCart}
        reset={reset}
      />
    </Elements>
  );
};

export default StripeContainer;
