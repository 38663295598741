import classes from './PageTitleBar.module.css'

const PageTitleBar = ({title}) => {
    return (
        <div className={classes.pageTitle}>
      <h1>{title}</h1>
    </div>
    )
}

export default PageTitleBar;