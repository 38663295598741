import { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { createClient } from "@supabase/supabase-js";

import emailjs from "@emailjs/browser";

import classes from "./Form.module.css";

import PageTitleBar from "../components/PageTitleBar";
import StateInput from "../components/StateInput";
import Button from "../components/Button";
import CheckoutContext from "../store/CheckoutContext";

import axios from "axios";

import { BallTriangle } from "react-loading-icons";

import { isValidInput, isFormValid } from "../util/formUtilities";

import {
  siteIP,
  emailService,
  emailJSPubKey,
  memberApplicationConfirmTemplate,
  memberApplicationNotificaitonTemplate,
  supaBaseURL,
  supaBaseKey,
} from "../util/backendUtilities";

const MembershipApplication = () => {
  const navigate = useNavigate();
  const checkoutCtx = useContext(CheckoutContext);

  const [companyName, setCompanyName] = useState();
  const [email, setEmail] = useState();
  const [website, setWebsite] = useState();
  const [repName, setRepName] = useState();
  const [repPhone, setRepPhone] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [zip, setZip] = useState();
  const [state, setState] = useState();
  const [businessType, setBusinessType] = useState();
  const [logo, setLogo] = useState();
  const [logoPreview, setLogoPreview] = useState(<></>);
  const [logoBackgroundColor, setLogoBackgroundColor] = useState("white");

  const [memberType, setMemberType] = useState();
  const [isMemberTypeContractor, setIsMemberTypeContractor] = useState(false);

  const [payMethod, setPayMethod] = useState();
  const [membershipPrice, setMembershipPrice] = useState();

  const [companyNameValid, setCompanyNameValid] = useState();
  const [emailValid, setEmailValid] = useState();
  const [repNameValid, setRepNameValid] = useState();
  const [repPhoneValid, setRepPhoneValid] = useState();
  const [streetValid, setStreetValid] = useState();
  const [cityValid, setCityValid] = useState();
  const [zipValid, setZipValid] = useState();
  const [stateValid, setStateValid] = useState();
  const [businessTypeValid, setBusinessTypeValid] = useState();
  const [memberTypeValid, setMemberTypeValid] = useState();
  const [payMethodValid, setPayMethodValid] = useState();

  const [didSubmitValid, setDidSubmitValid] = useState(false);

  const [submitMessage, setSubmitMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const bottomRef = useRef(null);

  const submitHandler = (e) => {
    e.preventDefault();

    const inputValidArr = [
      companyNameValid,
      emailValid,
      repNameValid,
      streetValid,
      cityValid,
      zipValid,
      stateValid,
      businessTypeValid,
      memberTypeValid,
      payMethodValid,
    ];

    const inputValidFuncArr = [
      setCompanyNameValid,
      setEmailValid,
      setRepNameValid,
      setStreetValid,
      setCityValid,
      setZipValid,
      setStateValid,
      setBusinessTypeValid,
      setMemberTypeValid,
      setPayMethodValid,
    ];

    setDidSubmitValid(isFormValid(inputValidArr, inputValidFuncArr));
  };

  const confirmHandler = (e) => {
    e.preventDefault();

    if (logo) {
      uploadFile();
    }

    setIsLoading(true);
    const newMember = {
      companyName,
      email,
      website,
      repName,
      repPhone,
      street,
      city,
      zip,
      state,
      businessType,
      memberType,
      imageFileName: `${logo ? logo.name : ""}`,
    };

    axios
      .post(`${siteIP}/add-affiliate`, newMember)
      .then((res) => {
        setConfirmSuccess(true);
        setSubmitMessage(
          <div className={`${classes.wrapper} ${classes.centerAlign}`}>
            <h2 style={{ color: "green" }}>{res.data.message}</h2>
            {payMethod === "Check" && (
              <>
                <p>Please mail a check to:</p>
                <p>Mississippi Asphalt Pavement Association</p>
                <p>711 N. Presidents St.</p>
                <p>Jackson, MS 39202</p>
                <p>for:</p>
                {/* <h2 style={{ color: "#ffa559" }}>{calculatedPrice}</h2> */}
                <br />
              </>
            )}
            {payMethod === "Credit Card" && (
              <>
                <p>
                  Please proceed to Checkout to complete payment for your
                  registration
                </p>
                <br />
                <Button
                  backgroundColor={"#FFA559"}
                  shadowColor={"#454545"}
                  title={"Proceed to Checkout"}
                  onClick={() => {
                    checkoutCtx.addItem({
                      itemName: `Member Application for ${memberType}`,
                      itemPrice: membershipPrice,
                    });
                    navigate("/checkout");
                  }}
                />
              </>
            )}
            {payMethod === "N/A" && (
              <>
                <p>Membership payment has not been made</p>
                <p>
                  Please contact the office to discuss processing payment for
                  your registration
                </p>
                <p>(601) 948-5495</p>
              </>
            )}
          </div>
        );
        setIsLoading(false);
        const templateParams = {
          to_email: email,
          to_name: `${repName} of ${companyName}`,
          company: companyName,
          rep_name: repName,
          phone: repPhone,
          email: email,
          website: website,
          street: street,
          city: city,
          state: state,
          zip: zip,
          business_type: businessType,
          member_type: memberType,
          pay_method: payMethod,
        };

        emailjs
          .send(
            `${emailService}`,
            `${memberApplicationConfirmTemplate}`,
            templateParams,
            `${emailJSPubKey}`
          )
          .then(() => console.log("Sent"))
          .catch((err) => console.log(err));

        emailjs.send(
          `${emailService}`,
          `${memberApplicationNotificaitonTemplate}`,
          templateParams,
          `${emailJSPubKey}`
        );
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setConfirmSuccess(true);
        setSubmitMessage(
          <div className={classes.wrapper}>
            <h2 style={{ color: "red" }}>{err.response.data.message}</h2>
          </div>
        );
        setIsLoading(false);
      });
  };

  const resetOnChange = () => {
    setSubmitMessage("");
    setDidSubmitValid(false);
    setConfirmSuccess(false);
  };

  const uploadFile = async () => {
    const supabase = createClient(supaBaseURL, supaBaseKey);

    if (logo.size <= 30000) {
      const { data, error } = await supabase.storage
        .from("mapa_aff_images")
        .upload(`${logo.name}`, logo);
      if (error) {
        console.log("Error uploading file", error);
        console.log(data);
      }
    } else {
      console.log("File is too large", logo.size);
    }
  };

  const getLogoPreview = (file) => {
    console.log(file.size);
    if (file) {
      if (file.size <= 30000) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setLogoPreview(
            <img
              style={{ width: "auto", height: "100px" }}
              src={e.target.result}
              alt="uploaded_logo"
            />
          );
        };
        reader.readAsDataURL(file);
      } else {
        setLogoPreview(
          <p style={{ color: "#454545" }}>
            Please choose a file that is 30MB or smaller
          </p>
        );
      }
    } else {
      setLogoPreview(<h2>Image did not load</h2>);
    }
  };

  return (
    <div>
      <PageTitleBar title="MEMBERSHIP APPLICATION" />
      <div className={`${classes.page}`}>
        <h1>COMPANY INFO</h1>
        <input
          autoComplete="no"
          id="company-name"
          type="text"
          placeholder="Company Name"
          className={`${classes.full} ${
            companyNameValid === false && classes.invalidInput
          }`}
          onFocus={() => setCompanyNameValid(true)}
          onBlur={(e) => {
            if (!isValidInput(e.target.value, "text")) {
              setCompanyNameValid(false);
            }
          }}
          onChange={(e) => {
            resetOnChange();
            if (isValidInput(e.target.value, "text")) {
              setCompanyName(e.target.value);
            }
          }}
        />
        <div className={classes.splitLine}>
          <input
            autoComplete="no"
            id="rep-name"
            type="text"
            placeholder="Representative Name"
            className={`${classes.half} ${
              repNameValid === false && classes.invalidInput
            }`}
            onFocus={() => setRepNameValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "text")) {
                setRepNameValid(false);
              }
            }}
            onChange={(e) => {
              resetOnChange();
              if (isValidInput(e.target.value, "text")) {
                setRepName(e.target.value);
              }
            }}
          />
          <input
            autoComplete="no"
            id="rep-phone"
            type="number"
            placeholder="Representative Phone"
            className={`${classes.half} ${
              repPhoneValid === false && classes.invalidInput
            }`}
            onFocus={() => setRepPhoneValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "phone")) {
                setRepPhoneValid(false);
              } else {
                setRepPhoneValid(true);
              }
            }}
            onChange={(e) => {
              resetOnChange();
              setRepPhone(e.target.value);
            }}
          />
        </div>
        <div className={classes.splitLine}>
          <input
            id="email"
            type="text"
            placeholder="Contact Email"
            className={`${classes.half} ${
              emailValid === false && classes.invalidInput
            }`}
            onFocus={() => setEmailValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "email")) {
                setEmailValid(false);
              }
            }}
            onChange={(e) => {
              resetOnChange();
              setEmail(e.target.value);
            }}
          />
          <input
            id="website"
            type="text"
            placeholder="Website (optional)"
            className={classes.half}
            onChange={(e) => {
              resetOnChange();
              setWebsite(e.target.value);
            }}
          />
        </div>
        <br />
        <br />
        <h1>COMPANY ADDRESS</h1>
        <input
          autoComplete="no"
          id="street"
          type="text"
          placeholder="Street"
          className={`${classes.full} ${
            streetValid === false && classes.invalidInput
          }`}
          onFocus={() => setStreetValid(true)}
          onBlur={(e) => {
            if (!isValidInput(e.target.value, "text")) {
              setStreetValid(false);
            }
          }}
          onChange={(e) => {
            resetOnChange();
            setStreet(e.target.value);
          }}
        />

        <div>
          <input
            autoComplete="no"
            id="city"
            type="text"
            placeholder="City"
            className={`${classes.half} ${
              cityValid === false && classes.invalidInput
            }`}
            onFocus={() => setCityValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "text")) {
                setCityValid(false);
              }
            }}
            onChange={(e) => {
              resetOnChange();
              setCity(e.target.value);
            }}
          />
          <input
            autoComplete="no"
            id="zip"
            type="text"
            placeholder="Zip"
            className={`${zipValid === false && classes.invalidInput}`}
            onFocus={() => setZipValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "zip")) {
                setZipValid(false);
              }
            }}
            onChange={(e) => {
              resetOnChange();
              setZip(e.target.value);
            }}
          />
          <StateInput
            id="state"
            isValidInput={stateValid === false ? false : true}
            onChange={(e) => {
              resetOnChange();
              if (e.target.value === "") {
                setStateValid(false);
              } else {
                setStateValid(true);
                setState(e.target.value);
              }
            }}
          />
        </div>
        <br />
        <br />
        <label htmlFor="file" className={classes.customUpload}>
          Upload Company Logo
        </label>
        <input
          type="file"
          id="file"
          accept="image/*"
          onChange={(e) => {
            setLogo(e.target.files[0]);
            getLogoPreview(e.target.files[0]);
          }}
        />
        {logo ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div
              style={{
                height: "200px",
                width: "fit-content",
                paddingBlock: "25px",
                paddingInline: "100px",
                backgroundColor: `${logoBackgroundColor}`,
                borderRadius: "12px",
                textAlign: "center",
                alignContent: "center",
              }}
            >
              {logoPreview}
            </div>
          </div>
        ) : (
          <p className={classes.centerAlign}>
            <i>
              Please upload your logo (30MB or smaller) so we can display it on
              our Members page
            </i>
          </p>
        )}
        {logo && (
          <div style={{ alignItems: "center", marginBottom: "25px" }}>
            <p>Background Color:</p>
            <span className={classes.checkbox}>
              <input
                type="radio"
                id="black"
                name="background-color"
                onChange={() => setLogoBackgroundColor("black")}
              />
              <label htmlFor="black">Black</label>
            </span>
            <span className={classes.checkbox}>
              <input
                type="radio"
                id="white"
                name="background-color"
                onChange={() => setLogoBackgroundColor("white")}
              />
              <label htmlFor="white">White</label>
            </span>
          </div>
        )}
        <br />
        <br />
        <h1>TYPE OF BUSINESS</h1>
        <select
          name="type"
          id="type"
          className={`${businessTypeValid === false && classes.invalidInput}`}
          onFocus={() => setBusinessTypeValid(true)}
          onBlur={(e) => {
            if (e.target.value === "select") {
              setBusinessTypeValid(false);
            }
          }}
          onChange={(e) => {
            resetOnChange();
            setBusinessType(e.target.value);
          }}
        >
          <option value="select">Select Business Type</option>
          <option value="asphalt-producer">Asphalt Producer</option>
          <option value="associate-contractor">Associate Contractor</option>
          <option value="bond-insurance">Bond & Insurance</option>
          <option value="consulting-engineer">Consulting Engineer</option>
          <option value="mat-paint-stripe-contactor">
            Materials Paint Striping Contractor
          </option>
          <option value="equipment-dealer">Equipment Dealer</option>
          <option value="materials-producer">Materials Producer</option>
          <option value="other">Other</option>
        </select>
        <br />
        <br />
        <div
          className={classes.flex}
          style={{ justifyContent: "space-around" }}
        >
          <div className={classes.centerAlign} style={{ marginTop: "50px" }}>
            <h1>TYPE OF MEMBERSHIP</h1>
            <h4
              style={{
                color: `${memberTypeValid === false ? "#fa7575" : "white"}`,
              }}
            >
              Select one of the following:
            </h4>
            <div className={classes.leftAlign}>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="member-type"
                  id="contractor"
                  value="Contractor"
                  onChange={(e) => {
                    resetOnChange();
                    setMemberTypeValid(true);
                    setMemberType(e.target.value);
                    setIsMemberTypeContractor(true);
                    setMembershipPrice("$1");
                  }}
                />
                <label htmlFor="contractor">Contractor</label>
              </span>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="member-type"
                  id="sustaining"
                  value="Sustaining"
                  onChange={(e) => {
                    resetOnChange();
                    setMemberTypeValid(true);
                    setIsMemberTypeContractor(false);
                    setMemberType(e.target.value);
                    setMembershipPrice("$6,000");
                  }}
                />
                <label htmlFor="sustaining">Sustaining Member - $6,000</label>
              </span>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="member-type"
                  id="assoc-contractor"
                  value="Associate Contractor"
                  onChange={(e) => {
                    resetOnChange();
                    setMemberTypeValid(true);
                    setIsMemberTypeContractor(false);
                    setMemberType(e.target.value);
                    setMembershipPrice("$1,750");
                  }}
                />
                <label htmlFor="assoc-contractor">
                  Associate Contractor Member - $1,750
                </label>
              </span>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="member-type"
                  id="associate"
                  value="Associate"
                  onChange={(e) => {
                    resetOnChange();
                    setMemberTypeValid(true);
                    setIsMemberTypeContractor(false);
                    setMemberType(e.target.value);
                    setMembershipPrice("$1,000");
                  }}
                />
                <label htmlFor="associate">Associate Member - $1,000</label>
              </span>
            </div>
          </div>
          <div className={classes.centerAlign} style={{ marginTop: "50px" }}>
            <h1>PREFERRED PAYMENT METHOD</h1>
            <h4
              style={{
                color: `${payMethodValid === false ? "#fa7575" : "white"}`,
              }}
            >
              Select one of the following:
            </h4>
            <div className={classes.leftAlign}>
              {!isMemberTypeContractor && (
                <span className={classes.checkbox}>
                  <input
                    type="radio"
                    name="pay-method"
                    id="credit"
                    value="Credit Card"
                    onChange={(e) => {
                      resetOnChange();
                      setPayMethodValid(true);
                      setPayMethod(e.target.value);
                    }}
                  />
                  <label htmlFor="credit">Credit Card</label>
                </span>
              )}
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="pay-method"
                  id="check"
                  value="Check"
                  onChange={(e) => {
                    resetOnChange();
                    setPayMethodValid(true);
                    setPayMethod(e.target.value);
                  }}
                />
                <label htmlFor="check">Check</label>
              </span>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="pay-method"
                  id="n/a"
                  value="N/A"
                  onChange={(e) => {
                    resetOnChange();
                    setPayMethodValid(true);
                    setPayMethod(e.target.value);
                  }}
                />
                <label htmlFor="n/a">N/A</label>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.confirmWrapper}>
          {didSubmitValid && (
            <>
              <h1>Confirm details before Submitting</h1>
              <div className={`${classes.confirm}`}>
                <p>Company Name: &nbsp;{companyName}</p>
                <p>Representative Name: &nbsp; {repName}</p>
                <p>Representative Phone: &nbsp; {repPhone}</p>
                <p>Email: &nbsp;{email}</p>
                <p>Website: &nbsp;{website ? website : "--"}</p>
                <p>
                  Address: &nbsp;{street} {city} {state} {zip}
                </p>
                <p>Type of Business: &nbsp;{businessType.toUpperCase()}</p>
                <p>Type of Membership: &nbsp;{memberType.toUpperCase()}</p>
                <p>Payment Method: &nbsp;{payMethod.toUpperCase()}</p>
              </div>
              <div>
                {isLoading && (
                  <div>
                    <BallTriangle
                      stroke="#FFA559"
                      fill="#FFA55990"
                      speed=".70"
                    />
                    <br />
                    <br />
                  </div>
                )}
                {submitMessage && submitMessage}
              </div>
            </>
          )}
        </div>
        {!confirmSuccess && (
          <div className={classes.centerAlign}>
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={didSubmitValid ? "Confirm" : "Submit"}
              onClick={(e) => {
                if (didSubmitValid && !confirmSuccess) {
                  confirmHandler(e);
                  bottomRef.current.scrollIntoView();
                } else if (!didSubmitValid) {
                  submitHandler(e);
                  bottomRef.current.scrollIntoView();
                }
              }}
            />
          </div>
        )}
        <br />
        <br />
        {isMemberTypeContractor && (
          <p className={classes.centerAlign}>
            Please contact the office to determine pricing for membership under
            Contractor status
          </p>
        )}
        <div ref={bottomRef}></div>
        <div style={{ minHeight: "500px" }}></div>
      </div>
    </div>
  );
};

export default MembershipApplication;
