import { useContext } from "react";
import PageTitleBar from "../components/PageTitleBar";
import classes from "./Affiliates.module.css";
import AffiliateContext from "../store/AffiliateContext";
import CardMajor from "../components/CardMajor";

const Affiliates = () => {
  const affiliateCtx = useContext(AffiliateContext);
  return (
    <div>
      <PageTitleBar title="MEMBERS" />
      <div className={`${classes.center} ${classes.background}`}>
        <div className={classes.grid}>
          {affiliateCtx.affiliates &&
            affiliateCtx.affiliates.map((aff) => (
              <CardMajor
                key={aff.aff_name + aff.aff_street}
                imageFile={aff.aff_image_file}
                blackBackground={aff.logoNeedsBlackBackground}
                imageBucket="mapa_aff_images"
                lineOne={aff.aff_name}
                lineTwo={`${aff.aff_street} `}
                lineThree={`${aff.aff_city}, ${aff.aff_state} ${aff.aff_zip}`}
                onClick={() => {
                  if (aff.aff_website) {
                    window.open(`${aff.aff_website}`);
                  }
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Affiliates;
