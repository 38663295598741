import classes from "./Button.module.css";

const Button = (props) => {
  const { backgroundColor, shadowColor, title, onClick, fontColor, disabled } =
    props;

  return (
    <button
      disabled={disabled ? disabled : false}
      style={{
        display: `${disabled ? "none" : "block"}`,
        backgroundColor: backgroundColor,
        boxShadow: `1px 3px 7px ${shadowColor}`,
        color: `${fontColor ? fontColor : "#fff"}`,
      }}
      onClick={onClick}
      className={classes.button}
    >
      {title}
    </button>
  );
};

export default Button;
