import { NavLink } from "react-router-dom";

import classes from "./Footer.module.css";

import { IconContext } from "react-icons";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook } from "react-icons/im";
import { ImLinkedin2 } from "react-icons/im";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.wrapper}>
        <div className={classes.pageSelections}>
          <NavLink to="/" className={() => classes.nav} end>
            Home
          </NavLink>
          <NavLink to="/about" className={() => classes.nav}>
            About
          </NavLink>
          <NavLink to="/contact" className={() => classes.nav}>
            Contact
          </NavLink>
          <NavLink to="/membership" className={() => classes.nav}>
            Membership
          </NavLink>
        </div>
        <div className={classes.socials}>
          <a
            href="https://twitter.com/AsphaltPaveMS"
            target="about.blank"
            className={classes.icon}
          >
            <IconContext.Provider value={{ size: "90%" }}>
              <BsTwitter />
            </IconContext.Provider>
          </a>
          <a
            href="https://www.instagram.com/asphaltpavems/"
            target="about.blank"
            className={classes.icon}
          >
            <IconContext.Provider value={{  size: "90%" }}>
              <BsInstagram />
            </IconContext.Provider>
          </a>
          <a
            href="https://www.facebook.com/asphaltpavems/?ref=br_rs"
            target="about.blank"
            className={classes.icon}
          >
            <IconContext.Provider value={{ size: "90%" }}>
              <ImFacebook />
            </IconContext.Provider>
          </a>
          <a
            href="https://www.linkedin.com/company/mississippi-asphalt-pavement-association-inc./"
            target="about.blank"
            className={classes.icon}
          >
            <IconContext.Provider value={{ size: "90%" }}>
              <ImLinkedin2 />
            </IconContext.Provider>
          </a>
        </div>
      </div>
        {/* <h2>© Mississippi Asphalt Pavement Association 2023</h2>    */}
    </div>
  );
};

export default Footer;
