import { useEffect, useState } from "react";
import classes from "./CardMinor.module.css";

import { supaBaseRefId } from "../util/backendUtilities";

const CardMinor = ({ imageFile, imageBucket, name }) => {
  const [imgDidLoad, setImgDidLoad] = useState(false);

  const imgUrl = `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/${imageBucket}/${imageFile}`;
  useEffect(() => {
    const img = new Image();
    img.onload = () => setImgDidLoad(true);
    img.onerror = () => setImgDidLoad(false);
    img.src = imgUrl;
  });

  return (
    <div className={classes.card}>
      <div className={classes.imgWrapper}>
        {imgDidLoad ? <img src={imgUrl} alt={imageFile} /> : <h1>{name}</h1>}
      </div>
    </div>
  );
};

export default CardMinor;
