import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./ContractsBidding.module.css";

import { siteIP, supaBaseRefId } from "../util/backendUtilities";

import PageTitleBar from "../components/PageTitleBar";
import Button from "../components/Button";
import axios from "axios";

import { IconContext } from "react-icons";
import { PiFilePdfDuotone } from "react-icons/pi";

const ContractsBidding = () => {
  const navigate = useNavigate();
  const [lettings, setLettings] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const scrollRef = useRef("scrollRef");

  const formatLink = (letting) => {
    return `https://${supaBaseRefId}.supabase.co/storage/v1/object/public/letting_pdfs/${letting.pdf_filename}`;
  };

  useEffect(() => {
    axios.get(`${siteIP}/get-lettings/${year}`).then((res) => {
      res.data.sort((a, b) => {
        const aDate = new Date(`${a.month} 1, ${a.year}`);
        const bDate = new Date(`${b.month} 1, ${b.year}`);
        return bDate - aDate;
      });
      setLettings(res.data);
    });
  }, [year]);

  return (
    <div>
      <div className={`${classes.bgLinear} ${classes.title}`}>
        <PageTitleBar title="CONTRACTS & BIDDING" />
        <p>
          Monthly Bid Lettings are held by the Mississippi Department of
          Transportation at 10:00am on the fourth Tuesday of each month with no
          letting in December.
        </p>
      </div>
      <div className={`${classes.bgOrange} ${classes.links}`} ref={scrollRef}>
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"Notice to Contractors"}
          onClick={() => navigate('/notice-to-contractors')}
        />
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"MDOT Letting Calendar"}
          onClick={() =>
            window.open("https://mdot.ms.gov/Applications/BidSystem/Home.aspx")
          }
        />
        <Button
          backgroundColor={"#454545"}
          shadowColor={"#454545"}
          title={"MDOT Proposed Letting"}
          onClick={() =>
            window.open(
              "https://mdot.ms.gov/applications/Schedule_of_Proposed_Projects/ProposedLetting.aspx"
            )
          }
        />
      </div>
      <div className={classes.title}>
        <h1>{year}</h1>
      </div>
      <div className={`${classes.bgLinear} ${classes.table}`}>
        <table>
          <tbody>
            <tr>
              <th>Year</th>
              <th>Month</th>
              <th>Report</th>
            </tr>
            {lettings &&
              lettings.map((letting) => (
                <tr key={`${letting.year}-${letting.month}-${letting.report}`}>
                  <td>{letting.year}</td>
                  <td>{letting.month}</td>
                  <td>
                    <a
                      href={formatLink(letting)}
                      target="about_blank"
                    >{`${letting.report}  `}</a>
                    <IconContext.Provider value={{ size: "8%" }}>
                      <PiFilePdfDuotone />
                    </IconContext.Provider>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className={`${classes.centerAlign} ${classes.bgLinearRev}`}>
        <br />
        <h1>Year Archives</h1>
        <div className={`${classes.grid}`}>
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#000000"}
            title={"2024"}
            onClick={() => {
              setYear("2024");
              scrollRef.current.scrollIntoView();
            }}
          />
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#454545"}
            title={"2023"}
            onClick={() => {
              setYear("2023");
              scrollRef.current.scrollIntoView();
            }}
          />
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#454545"}
            title={"2022"}
            onClick={() => {
              setYear("2022");
              scrollRef.current.scrollIntoView();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractsBidding;
