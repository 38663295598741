import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";

//* CUSTOM HOOK -- USE IN THE COMPONENT WHERE RECAPTCHA LIVES.  THEN
//* PASS THE TOKEN THAT IS RETURNED THROUGH THE setToken PROP OF THE RECAPTCHA
//* COMPONENT.  IF TOKEN IS GOOD, THIS HOOK RETURNS TRUE.
export const useVerifyRecaptcha = (token) => {
  const [isVerified, setIsVerfied] = useState(false);

  useEffect(() => {
    if (token && token !== "") {
      axios
        .post("http://localhost:4000/recaptcha", { token: token })
        .then((res) => {
          console.log(res.data);
          setIsVerfied(res.data);
        });
    }
  }, [token]);
  return isVerified;
};

const ReCaptcha = ({ setToken }) => {
  const recaptchaRef = useRef(null);
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  const recaptchaCB = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  // Define the component function to be called when reCAPTCHA loads
  const onRecaptchaLoad = () => {
    setIsRecaptchaLoaded(true);
  };

  useEffect(() => {
    // Assign the component function to the window callback
    window.onRecaptchaLoad = onRecaptchaLoad;

    if (!window.grecaptcha) {
      // Load the script only if it's not already available
      const script = document.createElement("script");
      script.src =
        "https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    } else if (window.grecaptcha && window.grecaptcha.render) {
      // If reCAPTCHA is already loaded, call the function directly
      onRecaptchaLoad();
    }

    // Clean up the global callback on component unmount
    return () => {
      window.onRecaptchaLoad = null;
    };
  }, []);

  useEffect(() => {
    if (isRecaptchaLoaded) {
      window.grecaptcha.render(recaptchaRef.current, {
        sitekey: "6LdLwicqAAAAAH2byIyUydgvxzQwnFkbSnG-y8Ud",
        callback: recaptchaCB, // Callback function to handle the token
      });
    }
  }, [isRecaptchaLoaded, recaptchaCB]);

  return <div ref={recaptchaRef} display="hidden"></div>;
};
export default ReCaptcha;
