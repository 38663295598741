import classes from "./SocialHeader.module.css";

import logo from "../src-assets/mapa-logo-white.svg";

import { IconContext } from "react-icons";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook } from "react-icons/im";
import { ImLinkedin2 } from "react-icons/im";

const SocialHeader = () => {
  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <img src={logo} alt="" />
      </div>
      <div className={classes.socials}>
        <a
          href="https://twitter.com/AsphaltPaveMS"
          target="about.blank"
          className={classes.icon}
        >
          <IconContext.Provider value={{ size: "100%" }}>
            <BsTwitter />
          </IconContext.Provider>
        </a>
        <a
          href="https://www.instagram.com/asphaltpavems/"
          target="about.blank"
          className={classes.icon}
        >
          <IconContext.Provider value={{ size: "100%" }}>
            <BsInstagram />
          </IconContext.Provider>
        </a>
        <a
          href="https://www.facebook.com/asphaltpavems/?ref=br_rs"
          target="about.blank"
          className={classes.icon}
        >
          <IconContext.Provider value={{ size: "100%" }}>
            <ImFacebook />
          </IconContext.Provider>
        </a>
        <a
          href="https://www.linkedin.com/company/mississippi-asphalt-pavement-association-inc./"
          target="about.blank"
          className={classes.icon}
        >
          <IconContext.Provider value={{ size: "100%" }}>
            <ImLinkedin2 />
          </IconContext.Provider>
        </a>
      </div>
    </div>
  );
};

export default SocialHeader;
