import { useContext, useEffect, useState } from "react";

import { IconContext } from "react-icons";
import { IoCloseCircleOutline } from "react-icons/io5";

import classes from "./Form.module.css";
import checkoutClass from "./Checkout.module.css";

import PageTitleBar from "../components/PageTitleBar";
import StateInput from "../components/StateInput";
import { isValidInput, isFormValid } from "../util/formUtilities";
import StripeContainer from "../components/StripeContainer";
import Button from "../components/Button";
import CheckoutContext from "../store/CheckoutContext";

import ReCaptcha, {useVerifyRecaptcha} from "../components/grecaptcha/ReCaptcha";

const Checkout = () => {
  const checkoutCtx = useContext(CheckoutContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [firstNameValid, setFirstNameValid] = useState();
  const [lastNameValid, setLastNameValid] = useState();
  const [streetValid, setStreetValid] = useState();
  const [cityValid, setCityValid] = useState();
  const [stateValid, setStateValid] = useState();
  const [zipValid, setZipValid] = useState();
  const [emailValid, setEmailValid] = useState();
  const [phoneValid, setPhoneValid] = useState();

  const [orderItems, setOrderItems] = useState([]);

  const [didProceedToCheckout, setDidProceedToCheckout] = useState(false);

  const [isPayingInv, setIsPayingInv] = useState(false);
  const [invoiceNum, setInvoiceNum] = useState("");
  const [invoiceAmt, setInvoiceAmt] = useState("");

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaIsVerified = useVerifyRecaptcha(recaptchaToken);

  useEffect(() => {
    setOrderItems(checkoutCtx.items);
  }, [checkoutCtx.items]);

  const submitHandler = () => {
    if (recaptchaIsVerified) {
      const inputValidArr = [
        firstNameValid,
        lastNameValid,
        streetValid,
        cityValid,
        zipValid,
        stateValid,
        emailValid,
        phoneValid,
      ];

      const inputValidFuncArr = [
        setFirstNameValid,
        setLastNameValid,
        setStreetValid,
        setCityValid,
        setZipValid,
        setStateValid,
        setEmailValid,
        setPhoneValid,
      ];

      setDidProceedToCheckout(isFormValid(inputValidArr, inputValidFuncArr));
      setDidProceedToCheckout(true);
    } else {
      alert("Please complete the reCAPTCHA checkbox")
    }
  };

  const customerInfo = {
    firstName,
    lastName,
    street,
    city,
    zip,
    state,
    email,
    phone,
    orderItems,
  };

  const calcSubTotal = () => {
    const itemPrices = [];
    orderItems.forEach((item) => {
      const price = +item.itemPrice.substring(1).replace(",", "");
      itemPrices.push(price);
    });
    return itemPrices.reduce((acc, curr) => acc + curr, 0);
  };

  const threePercentOfTotal = () => {
    return calcSubTotal() * 0.03;
  };

  const { format } = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const enterInvoice = () => {
    console.log(invoiceNum, invoiceAmt);
    checkoutCtx.addItem({
      itemName: `Invoice# ${invoiceNum}`,
      itemPrice: invoiceAmt,
    });
    setInvoiceNum("");
    setInvoiceAmt("");
    setIsPayingInv(false);
  };

  return (
    <div>
      <PageTitleBar title={"CHECKOUT"} />
      <div className={classes.page}>
        {!isPayingInv && (
          <div style={{ textAlign: "left" }}>
            <Button
              fontColor="#454545"
              backgroundColor={"#FFF"}
              shadowColor={"#454545"}
              title={"Paying an Invoice?"}
              onClick={() => setIsPayingInv(true)}
            />
          </div>
        )}
        {isPayingInv && (
          <div className={checkoutClass.enterInv}>
            <div style={{ padding: "15px", textAlign: "right" }}>
              <IconContext.Provider
                value={{ style: { color: "#454545", marginLeft: "10px" } }}
              >
                <IoCloseCircleOutline onClick={() => setIsPayingInv(false)} />
              </IconContext.Provider>
            </div>

            <label htmlFor="invoice">Enter Invoice #</label>

            <input
              type="text"
              id="invoice"
              value={invoiceNum}
              onChange={(e) => setInvoiceNum(e.target.value)}
            />

            <label htmlFor="amount">Enter Amount</label>

            <input
              type="text"
              id="amount"
              value={invoiceAmt}
              onChange={(e) => {
                const value = e.target.value.replace("$", "");
                setInvoiceAmt(`$${value}`);
              }}
            />

            <br />
            <br />
            <Button
              fontColor="#fff"
              backgroundColor={"#454545"}
              shadowColor={"#454545"}
              title={"Enter"}
              onClick={() => enterInvoice()}
            />
          </div>
        )}
        <br />
        <br />
        <div>
          {orderItems.length !== 0 && (
            <>
              <h1 style={{ textAlign: "left" }}>Order Summary:</h1>
              <table className={checkoutClass.summaryTable}>
                <tbody>
                  <tr>
                    <th>
                      <h2>Item</h2>
                    </th>
                    <th>
                      <h2>Price</h2>
                    </th>
                  </tr>
                  {orderItems.map((item, index) => (
                    <tr key={index}>
                      <th>
                        <p style={{ margin: "2px" }}>{item.itemName}</p>
                      </th>
                      <th>
                        <p style={{ margin: "2px" }}>{item.itemPrice}</p>
                      </th>
                      <th>
                        <IconContext.Provider
                          value={{ style: { color: "#ffa559" } }}
                        >
                          <IoCloseCircleOutline
                            onClick={() => checkoutCtx.removeItem(index)}
                          />
                        </IconContext.Provider>
                      </th>
                    </tr>
                  ))}
                  <tr>
                    <th>
                      <p style={{ marginBottom: "2px" }}>3% fee</p>
                    </th>
                    <th>
                      <p style={{ marginBottom: "2px" }}>
                        {format(threePercentOfTotal())}
                      </p>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <h2 style={{ marginBlock: "6px", color: "#ffa559" }}>
                        Total:
                      </h2>
                    </th>
                    <th>
                      <h2 style={{ marginBlock: "6px", color: "#ffa559" }}>
                        {format(calcSubTotal() + threePercentOfTotal())}
                      </h2>
                    </th>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ textAlign: "left" }}>
                <i>A 3% fee is added for credit card payments</i>
              </h4>
            </>
          )}

          <br />
          <br />
        </div>
        <form>
          <input
            autoComplete="no"
            id="first-name"
            type="text"
            placeholder="First Name"
            value={firstName}
            className={`${classes.half} ${
              firstNameValid === false && classes.invalidInput
            }`}
            onFocus={() => setFirstNameValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "text")) {
                setFirstNameValid(false);
              }
            }}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            autoComplete="no"
            id="last-name"
            type="text"
            placeholder="Last Name"
            value={lastName}
            className={`${classes.half} ${
              lastNameValid === false && classes.invalidInput
            }`}
            onFocus={() => setLastNameValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "text")) {
                setLastNameValid(false);
              }
            }}
            onChange={(e) => setLastName(e.target.value)}
          />
          <input
            autoComplete="no"
            id="street"
            type="text"
            placeholder="Street"
            value={street}
            className={`${classes.full} ${
              streetValid === false && classes.invalidInput
            }`}
            onFocus={() => setStreetValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "text")) {
                setStreetValid(false);
              }
            }}
            onChange={(e) => setStreet(e.target.value)}
          />
          <div>
            <input
              autoComplete="no"
              id="city"
              type="text"
              placeholder="City"
              value={city}
              className={`${classes.half} ${
                cityValid === false && classes.invalidInput
              }`}
              onFocus={() => setCityValid(true)}
              onBlur={(e) => {
                if (!isValidInput(e.target.value, "text")) {
                  setCityValid(false);
                }
              }}
              onChange={(e) => setCity(e.target.value)}
            />
            <input
              autoComplete="no"
              id="zip"
              type="text"
              placeholder="Zip"
              value={zip}
              className={`${zipValid === false && classes.invalidInput}`}
              onFocus={() => setZipValid(true)}
              onBlur={(e) => {
                if (!isValidInput(e.target.value, "zip")) {
                  setZipValid(false);
                }
              }}
              onChange={(e) => {
                setZip(e.target.value);
              }}
            />
            <StateInput
              id="state"
              value={state}
              isValidInput={stateValid === false ? false : true}
              onChange={(e) => {
                if (e.target.value === "") {
                  setStateValid(false);
                } else {
                  setStateValid(true);
                  setState(e.target.value);
                }
              }}
            />
          </div>
          <input
            type="text"
            placeholder="Email"
            value={email}
            className={`${classes.half} ${
              emailValid === false && classes.invalidInput
            }`}
            onFocus={() => setEmailValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "email")) {
                setEmailValid(false);
              }
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="Phone Number"
            value={phone}
            className={`${classes.half} ${
              phoneValid === false && classes.invalidInput
            }`}
            onFocus={() => setPhoneValid(true)}
            onBlur={(e) => {
              if (!isValidInput(e.target.value, "phone")) {
                setPhoneValid(false);
              }
            }}
            onChange={(e) => setPhone(e.target.value)}
          />
        </form>
        <br />
        <br />
        <br />
        <span>
          <StripeContainer
            setDidProceed={setDidProceedToCheckout}
            didProceed={didProceedToCheckout}
            customerInfo={customerInfo}
            orderTotal={calcSubTotal() + threePercentOfTotal()}
            clearCart={setOrderItems}
            reset={() => {
              setFirstName("");
              setLastName("");
              setStreet("");
              setCity("");
              setZip("");
              setEmail("");
              setPhone("");
              setState("");
            }}
          />
        </span>
        <div className={checkoutClass.centerAlign} style={{display: `${didProceedToCheckout ? "none" : "flex"}`}}>
          <ReCaptcha setToken={setRecaptchaToken}/>
        </div>
        <br />
        <div className={checkoutClass.centerAlign}>
          {!didProceedToCheckout && (
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={"Submit"}
              onClick={() => {
                submitHandler();
              }}
            />
          )}
        </div>
        <div style={{ minHeight: "150px" }}></div>
      </div>
    </div>
  );
};

export default Checkout;
