//* TODO: Figure out how to update isValid state when autocomplete is enabled.

import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import emailjs from "@emailjs/browser";

import CheckoutContext from "../store/CheckoutContext";
import PageTitleBar from "../components/PageTitleBar";
import StateInput from "../components/StateInput";
import classes from "./Form.module.css";
import Button from "../components/Button";

import { BallTriangle } from "react-loading-icons";

import { isFormValid, isValidInput, price } from "../util/formUtilities";

import { siteIP, emailService, emailJSPubKey } from "../util/backendUtilities";

const CertRegistrationForm = () => {
  const checkoutCtx = useContext(CheckoutContext);
  const navigate = useNavigate();

  const [certID, setCertID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState();
  const [isReCert, setIsReCert] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [classSelection, setClassSelection] = useState("");
  const [payMethod, setPayMethod] = useState();
  const [calculatedPrice, setCalculatedPrice] = useState();

  const [classSelectIsValid, setClassSelectIsValid] = useState();
  const [certIsValid, setCertIsValid] = useState();
  const [firstNameIsValid, setFirstNameIsValid] = useState();
  const [lastNameIsValid, setLastNameIsValid] = useState();
  const [streetIsValid, setStreetIsValid] = useState();
  const [cityIsValid, setCityIsValid] = useState();
  const [stateIsValid, setStateIsValid] = useState();
  const [zipIsValid, setZipIsValid] = useState();
  const [emailIsValid, setEmailIsValid] = useState();
  const [phoneIsValid, setPhoneIsValid] = useState();
  const [companyIsValid, setCompanyIsValid] = useState();
  // const [isMemberValid, setIsMemberValid] = useState();
  const [payMethodValid, setPayMethodValid] = useState();

  const [didSubmitValid, setDidSubmitValid] = useState(false);

  const [submitMessage, setSubmitMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const bottomRef = useRef(null);

  const certPlaceholder = () => {
    switch (classSelection) {
      case "":
        return "Enter ID/Certification #: (Please select your class first)";
      case "1":
        if (isReCert) {
          return "Enter ID/Certification #";
        } else {
          return "Enter ID/Certification #: (ID # will be emailed to you after registration is complete)";
        }
      default:
        return "Enter ID/Certification #";
    }
  };

  const classSel = (v) => {
    switch (v) {
      case "1":
        return "Level 1";
      case "2":
        return "Level 2";
      case "3":
        return "Level 3";
      case "4":
        return "Master";
      default:
        return;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setSubmitMessage("");
    setCalculatedPrice(
      isMember
        ? price(isReCert, classSelection).member
        : price(isReCert, classSelection).non
    );
    const inputValidArr = [
      classSelectIsValid,
      firstNameIsValid,
      lastNameIsValid,
      streetIsValid,
      cityIsValid,
      stateIsValid,
      zipIsValid,
      emailIsValid,
      phoneIsValid,
      companyIsValid,
      payMethodValid,
    ];
    const inputValidFuncArr = [
      setClassSelectIsValid,
      setFirstNameIsValid,
      setLastNameIsValid,
      setStreetIsValid,
      setCityIsValid,
      setStateIsValid,
      setZipIsValid,
      setEmailIsValid,
      setPhoneIsValid,
      setCompanyIsValid,
      setPayMethodValid,
    ];

    setDidSubmitValid(isFormValid(inputValidArr, inputValidFuncArr));

    if (certIsValid === false) {
      setDidSubmitValid(false);
    }
  };

  const confirmHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const body = {
      classSelection,
      certID,
      firstName,
      lastName,
      street,
      city,
      state,
      zip,
      email,
      phone,
      company,
    };

    axios
      .post(`${siteIP}/cert-registration`, body)
      .then(({ data }) => {
        setConfirmSuccess(true);
        setSubmitMessage(
          <div className={`${classes.wrapper} ${classes.centerAlign}`}>
            <h2 style={{ color: "green" }}>Registration Successful!</h2>
            <br />
            <p>A confirmation email will be sent to:</p>
            <p>{data.email}</p>
            <br />
            <p style={{ margin: "2px" }}>Registration ID:</p>
            <h2>{data.cert_id}</h2>
            <br />
            {payMethod === "Check" && (
              <>
                <p>Please mail a check to:</p>
                <p>Mississippi Asphalt Pavement Association</p>
                <p>711 N. Presidents St.</p>
                <p>Jackson, MS 39202</p>
                <p>for:</p>
                <h2 style={{ color: "#ffa559" }}>{calculatedPrice}</h2>
                <br />
              </>
            )}
            {payMethod === "Credit Card" && (
              <>
                <p>
                  Please proceed to Checkout to complete payment for you
                  registration
                </p>
                <br />
                <Button
                  backgroundColor={"#FFA559"}
                  shadowColor={"#454545"}
                  title={"Proceed to Checkout"}
                  onClick={() => {
                    checkoutCtx.addItem({
                      itemName: `${classSel(classSelection)} ${
                        isReCert ? "ReCertification" : "Certification"
                      } Registration`,
                      itemPrice: calculatedPrice,
                    });
                    navigate("/checkout");
                  }}
                />
              </>
            )}
            {payMethod === "N/A" && (
              <>
                <p>Registration payment has not been made</p>
                <p>
                  Please contact the office to discuss processing payment for
                  your registration
                </p>
                <p>(601) 948-5495</p>
              </>
            )}
          </div>
        );
        setIsLoading(false);
        const templateParams = {
          to_email: data.email,
          to_name: `${data.first_name} ${data.last_name}`,
          class: `${classSel(classSelection)}`,
          cert_type: `${isReCert ? "Re-Certification" : "Certification"}`,
          cert_id: data.cert_id,
          reg_timestamp: new Date().toString(),
          message: "Test message from Cert Registration",
        };

        //TODO: When changing to production service and template use .env for IDs
        emailjs
          .send(
            `${emailService}`,
            "template_cgi1t57",
            templateParams,
            `${emailJSPubKey}`
          )
          .then(() => console.log("Sent"))
          .catch((err) => console.log(err));
      })
      .catch((res) => {
        console.log(res);
        const data = res.response.data;
        setSubmitMessage(
          <div className={classes.wrapper}>
            {data.message && <h2 style={{ color: "red" }}>{data.message}</h2>}
            <br />
            {data.instruction && <p>{data.instruction}</p>}
            <br />
            {data.number && <p>{data.number}</p>}
            {data.email && <p>{data.email}</p>}
            <br />
            <br />

            {data.error && (
              <>
                <p>ERROR:</p>
                <p style={{ color: "red" }}>{data.error.name}</p>
              </>
            )}
          </div>
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageTitleBar title="CLASS REGISTRATION" />
      <div className={classes.page}>
        <form>
          <div>
            <select
              name="classSelect"
              id="classSelect"
              className={`${classes.half} ${
                classSelectIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setClassSelectIsValid(true)}
              onBlur={(e) => {
                setClassSelectIsValid(isValidInput(e.target.value, "text"));
              }}
              onChange={(e) => {
                setClassSelection(e.target.value);
                setDidSubmitValid(false);
                setConfirmSuccess(false);

                if (+e.target.value > 1) {
                  setCertIsValid(false);
                } else if (+e.target.value === 1) {
                  setCertIsValid(true);
                } else if (+e.target.value === 1 && isReCert) {
                  setCertIsValid(false);
                } else {
                  setCertID("");
                }
              }}
            >
              <option value="">
                {classSelectIsValid === false
                  ? "Must select a class"
                  : "Select a class"}
              </option>
              <option value="1">Level 1 Certification</option>
              <option value="2">Level 2 Certification</option>
              <option value="3">Level 3 Certification</option>
              <option value="4">Master Certification</option>
            </select>
            <input
              id="certID"
              type="text"
              placeholder={certPlaceholder()}
              value={certID || ""}
              className={`${classes.half} ${
                certIsValid === false && classes.invalidInput
              } ${
                classSelection === "" || (classSelection === "1" && !isReCert)
                  ? classes.disabled
                  : ""
              }`}
              onFocus={() => setCertIsValid(true)}
              onBlur={(e) => {
                if (
                  classSelection === "" ||
                  (classSelection === "1" && !isReCert)
                ) {
                  setCertIsValid(true);
                } else {
                  setCertIsValid(isValidInput(e.target.value, "text"));
                }
              }}
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setCertID(e.target.value);
              }}
              tabIndex={
                classSelection === "" || (classSelection === "1" && !isReCert)
                  ? -1
                  : 0
              }
            />
          </div>
          <div>
            <input
              autoComplete="no"
              id="firstName"
              type="text"
              placeholder={`${
                firstNameIsValid === false
                  ? "Must enter a first name"
                  : "First Name"
              }`}
              className={`${classes.half} ${
                firstNameIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setFirstNameIsValid(true)}
              onBlur={(e) =>
                setFirstNameIsValid(isValidInput(e.target.value, "text"))
              }
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setFirstName(e.target.value);
              }}
            />
            <input
              autoComplete="no"
              id="lastName"
              type="text"
              placeholder={
                lastNameIsValid === false
                  ? "Must enter a last name"
                  : "Last Name"
              }
              className={`${classes.half} ${
                lastNameIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setLastNameIsValid(true)}
              onBlur={(e) =>
                setLastNameIsValid(isValidInput(e.target.value, "text"))
              }
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setLastName(e.target.value);
              }}
            />
          </div>
          <input
            autoComplete="no"
            id="street"
            type="text"
            placeholder={
              streetIsValid === false
                ? "Must enter street address"
                : "Street Address"
            }
            className={`${classes.full} ${
              streetIsValid === false && classes.invalidInput
            }`}
            onFocus={() => setStreetIsValid(true)}
            onBlur={(e) =>
              setStreetIsValid(isValidInput(e.target.value, "text"))
            }
            onChange={(e) => {
              setDidSubmitValid(false);
              setConfirmSuccess(false);
              setStreet(e.target.value);
            }}
          />
          <div>
            <input
              autoComplete="no"
              id="city"
              type="text"
              placeholder={cityIsValid === false ? "Must enter a city" : "City"}
              className={`${classes.half} ${
                cityIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setCityIsValid(true)}
              onBlur={(e) =>
                setCityIsValid(isValidInput(e.target.value, "text"))
              }
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setCity(e.target.value);
              }}
            />
            <input
              autoComplete="no"
              id="zip"
              type="number"
              placeholder={
                zipIsValid === false ? "Must enter zip code" : "Zip Code"
              }
              className={`${zipIsValid === false && classes.invalidInput}`}
              onFocus={() => setZipIsValid(true)}
              onBlur={(e) => setZipIsValid(isValidInput(e.target.value, "zip"))}
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setZip(e.target.value);
              }}
            />
            <StateInput
              className={classes.half}
              id="state"
              isValidInput={stateIsValid === false ? false : true}
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                if (e.target.value === "") {
                  setStateIsValid(false);
                } else {
                  setStateIsValid(true);
                }
                setState(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              autoComplete="no"
              id="email"
              type="text"
              placeholder={
                emailIsValid === false
                  ? "Must enter email address"
                  : "Email address"
              }
              className={`${classes.half} ${
                emailIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setEmailIsValid(true)}
              onBlur={(e) =>
                setEmailIsValid(isValidInput(e.target.value, "email"))
              }
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setEmail(e.target.value);
              }}
            />
            <input
              autoComplete="no"
              id="phone"
              type="tel"
              placeholder={
                phoneIsValid === false
                  ? "Must enter phone number"
                  : "Phone number"
              }
              className={`${classes.half} ${
                phoneIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setPhoneIsValid(true)}
              onBlur={(e) =>
                setPhoneIsValid(isValidInput(e.target.value, "phone"))
              }
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setPhone(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              autoComplete="no"
              id="company"
              type="text"
              placeholder={
                companyIsValid === false ? "Must enter Company" : "Company"
              }
              className={`${classes.half} ${
                companyIsValid === false && classes.invalidInput
              }`}
              onFocus={() => setCompanyIsValid(true)}
              onBlur={(e) =>
                setCompanyIsValid(isValidInput(e.target.value, "text"))
              }
              onChange={(e) => {
                setDidSubmitValid(false);
                setConfirmSuccess(false);
                setCompany(e.target.value);
              }}
            />
            <span className={`${classes.checkbox}`}>
              <input
                id="reCert"
                type="checkbox"
                placeholder="ReCert"
                onChange={(e) => {
                  if (+classSelection === 1 && certID === "") {
                    setCertIsValid(false);
                  }
                  setIsReCert(e.target.checked);
                }}
              />
              <label htmlFor="reCert">Re-certification</label>
            </span>
            <span className={`${classes.checkbox}`}>
              <input
                id="isMember"
                type="checkbox"
                placeholder="Active Membership"
                onChange={(e) => {
                  setIsMember(e.target.checked);
                }}
              />
              <label htmlFor="reCert">Active Membership</label>
            </span>
          </div>
          <div className={classes.centerAlign} style={{ marginTop: "50px" }}>
            <h1>PREFERRED PAYMENT METHOD</h1>
            <h4
              style={{
                color: `${payMethodValid === false ? "#fa7575" : "white"}`,
              }}
            >
              Select one of the following:
            </h4>
            <div className={classes.leftAlign}>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="pay-method"
                  id="credit"
                  value="Credit Card"
                  onChange={(e) => {
                    setPayMethodValid(true);
                    setPayMethod(e.target.value);
                  }}
                />
                <label htmlFor="credit">Credit Card</label>
              </span>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="pay-method"
                  id="check"
                  value="Check"
                  onChange={(e) => {
                    setPayMethodValid(true);
                    setPayMethod(e.target.value);
                  }}
                />
                <label htmlFor="check">Check</label>
              </span>
              <span className={classes.checkbox}>
                <input
                  type="radio"
                  name="pay-method"
                  id="n/a"
                  value="N/A"
                  onChange={(e) => {
                    setPayMethodValid(true);
                    setPayMethod(e.target.value);
                  }}
                />
                <label htmlFor="n/a">N/A</label>
              </span>
            </div>
          </div>
          <div style={{ paddingBottom: "5%" }} className={classes.centerAlign}>
            {payMethod === "Check" && (
              <>
                <p>
                  Please mail a check made out to{" "}
                  <strong>Mississippi Asphalt Paving Association </strong>
                  for the amount above depending on your membership status to
                  the address below to complete your registration. A
                  confirmation email will be sent to the email address provided
                  once your registration process has been completed.
                </p>
                <p>711 N. Presidents St. Jackson, MS 39202</p>
              </>
            )}
            {payMethod === "N/A" && (
              <>
                <p>
                  After submitting registration please contact the office to
                  discuss payment.
                </p>
                <p>(601) 948-5495</p>
              </>
            )}
            {payMethod === "Credit Card" && (
              <>
                <p>
                  After completing your registration you will be directed to
                  Checkout
                </p>
              </>
            )}
            <div className={`${classes.price} ${classes.flex}`}>
              <h2>Membership Price:&nbsp;&nbsp;</h2>
              <h3>{price(isReCert, classSelection).member}</h3>
            </div>
            <div className={`${classes.price} ${classes.flex}`}>
              <h2>Non-member Price:&nbsp;&nbsp;</h2>
              <h3>{price(isReCert, classSelection).non}</h3>
            </div>
          </div>
          <p
            style={{
              color: "#df0b0b",
              fontFamily: "Open Sans",
              fontSize: "20px",
            }}
          ></p>
        </form>
        <div className={classes.confirmWrapper}>
          {didSubmitValid && (
            <>
              <h1>Confirm details before Submitting</h1>
              <div className={`${classes.confirm}`}>
                <h3>{isReCert ? "Re-certification" : ""}</h3>
                <p>Class: &nbsp;{classSel(classSelection)}</p>
                <p>
                  Name: &nbsp;{firstName} {lastName}
                </p>
                <p>
                  Address: &nbsp;{street} {city} {state} {zip}
                </p>
                <p>Email: &nbsp;{email}</p>
                <p>Phone: &nbsp;{phone}</p>
                <p>Company: &nbsp;{company}</p>
                <p>
                  Membership Status: &nbsp; {isMember ? "Active" : "Non-Member"}
                </p>
                <p>Payment Method: &nbsp;{payMethod}</p>
                <p>Price: &nbsp; {calculatedPrice}</p>
              </div>
              <div>
                {isLoading && (
                  <div>
                    <BallTriangle
                      stroke="#FFA559"
                      fill="#FFA55990"
                      speed=".70"
                    />
                    <br />
                    <br />
                  </div>
                )}
                {submitMessage && submitMessage}
              </div>
            </>
          )}
        </div>
        {!confirmSuccess && (
          <div className={classes.centerAlign}>
            <Button
              backgroundColor={"#FFA559"}
              shadowColor={"#454545"}
              title={didSubmitValid ? "Confirm" : "Submit"}
              onClick={(e) => {
                if (didSubmitValid && !confirmSuccess) {
                  confirmHandler(e);
                  bottomRef.current.scrollIntoView();
                } else if (!didSubmitValid) {
                  submitHandler(e);
                  bottomRef.current.scrollIntoView();
                }
              }}
            />
          </div>
        )}
        <br />
        <br />
        <div ref={bottomRef}></div>
        <div style={{ minHeight: "500px" }}></div>
      </div>
    </>
  );
};

export default CertRegistrationForm;
