import classes from "./About.module.css";

import PageTitleBar from "../components/PageTitleBar";
import Button from "../components/Button";

import heroImage from ".//assets/about-photo.png";
import missionPhoto from ".//assets/about-mission-photo.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.center}>
      <PageTitleBar title="ABOUT" />
      <div className={classes.heroImage}>
        <img src={heroImage} alt=""  />
      </div>
      <div className={classes.aboutUs}>
        <h1>
          Mississippi Asphalt Pavement Association is committed to the ongoing
          development and implementation of mutually beneficial programs for its
          membership through the following objectives:
        </h1>
        <ul>
          <li>
            Quality Pavement is the pinnacle of Mississippi Asphalt Pavement
            Association
          </li>
          <li>
            Promotion of our product to the public and private sector with the
            full knowledge of the intrinsic advantages belonging to our product.
          </li>
          <li>
            Foster educational opportunities for Mississippi resident students
            of our Universities and Junior Colleges to provide an employee pool
            of professionals for the highway construction industry.
          </li>
          <li>
            Bring about cooperation and mutual confidence among members to
            continually elevate the status of the asphalt paving industry.
          </li>
          <li>Establish uniform standards of operation and practices.</li>
          <li>
            Encourage the timely construction, repair, and maintenance of
            Mississippi’s public, private, and commercial highways, roads and
            streets.
          </li>
          <li>
            Educate public officials, government agencies, and the general
            public on the advantages of quality hot-mix asphalt pavements.
          </li>
          <li>
            Provide ongoing education and training opportunities for members.
          </li>
          <li>
            Provide technical resources and support to assist member companies
            in attaining the highest health, safety, and environmental
            practices.
          </li>
          <li>
            Represent members’ viewpoints on environmental issues to appropriate
            agencies and the general public.
          </li>
          <li>
            Support the commission and use of research in all phases of the
            asphalt process in order to improve industry products and
            technologies.
          </li>
          <li>
            Provide informed and vigorous support of all sound legislative
            proposals affecting the asphalt industry.
          </li>
        </ul>
        <div className={`${classes.flex} ${classes.missionWrapper}`}>
          <img src={missionPhoto} alt="" />
          <div className={classes.mission}>
            <h1>MISSION</h1>
            <ul>
              <li>
                Improve communications and understanding within the entire
                asphalt paving industry.
              </li>
              <li>
                Influence fair and equitable design, specifications and
                inspection.
              </li>
              <li>Protect the doctrine of free enterprise and competition.</li>
              <li>
                Stimulate and encourage more extensive research relative to the
                manufacture and use of asphalt pavements.
              </li>
              <li>
                Educate within the industry for higher standards, lawful trade
                practices and lawful ethical conduct; to act and function as an
                educational Association on behalf of the asphalt paving
                industry.
              </li>
              <li>
                Do any and all things lawful that may be of benefit to the
                members of the Association or other business interests and for
                improving the physical, mental and moral condition of mankind.
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.contactCall}>
          <h1>Colton Robison</h1>
          <h1>Executive Director</h1>
          <a href="mailto:">colton@asphaltpavems.com</a>
          <br />
          <br />
          <br />
          <br />
          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"Contact Us!"}
            onClick={() => navigate("/contact")}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
