import classes from "./Form.module.css";

import emailjs from "@emailjs/browser";

import Button from "../components/Button";
import PageTitleBar from "../components/PageTitleBar";

import heroImage from "./assets/contact-image.png";

import { isValidInput, isFormValid } from "../util/formUtilities";
import { useState } from "react";
import {
  contactFormEmailTemplate,
  emailJSPubKey,
  emailService,
} from "../util/backendUtilities";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [emailSentMessage, setEmailSentMessage] = useState();

  const [firstNameValid, setFirstNameValid] = useState();
  const [lastNameValid, setLastNameValid] = useState();
  const [emailValid, setEmailValid] = useState();
  const [phoneValid, setPhoneValid] = useState();
  const [messageValid, setMessageValid] = useState();

  const submitHandler = (e) => {
    const inputValidArr = [
      firstNameValid,
      lastNameValid,
      emailValid,
      phoneValid,
      messageValid,
    ];

    const inputValidFuncArr = [
      setFirstNameValid,
      setLastNameValid,
      setEmailValid,
      setPhoneValid,
      setMessageValid,
    ];

    if (isFormValid(inputValidArr, inputValidFuncArr)) {
      //* Setup EmailJS to complete Contact Us page
      const templateParams = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        message: message,
        timestamp: new Date().toString(),
      };
      emailjs
        .send(
          emailService,
          contactFormEmailTemplate,
          templateParams,
          emailJSPubKey
        )
        .then(() => {
          setEmailSentMessage(
            "Your message has been sent.  You will be contacted at the email you provided as soon as possible"
          );
          //TODO: RESET THE FORM
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
        })
        .catch((err) => console.log(err));
    } else {
      //TODO: NOTIFY USER THAT THE FORM IS INCOMPLETE OR INCORRECT
    }
  };

  return (
    <div>
      <PageTitleBar title="CONTACT" />
      <div>
        <img src={heroImage} alt="" style={{ width: "100%" }} />
      </div>
      <div className={classes.page}>
        <div className={classes.form}>
          <h1>Get in Touch</h1>
          <form>
            <input
              autoComplete="no"
              id="first-name"
              type="text"
              placeholder="First Name"
              className={`${classes.half} ${
                firstNameValid === false && classes.invalidInput
              }`}
              onFocus={() => setFirstNameValid(true)}
              onBlur={(e) => {
                setFirstNameValid(isValidInput(e.target.value, "text"));
              }}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
            />
            <input
              autoComplete="no"
              id="last-name"
              type="text"
              placeholder="Last Name"
              className={`${classes.half} ${
                lastNameValid === false && classes.invalidInput
              }`}
              onFocus={() => setLastNameValid(true)}
              onBlur={(e) => {
                setLastNameValid(isValidInput(e.target.value, "text"));
              }}
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            <input
              autoComplete="no"
              id="email"
              type="text"
              placeholder="Email"
              className={`${classes.full} ${
                emailValid === false && classes.invalidInput
              }`}
              onFocus={() => setEmailValid(true)}
              onBlur={(e) => {
                setEmailValid(isValidInput(e.target.value, "email"));
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <input
              autoComplete="no"
              id="phone"
              type="number"
              placeholder="Phone Number"
              className={`${classes.full} ${
                phoneValid === false && classes.invalidInput
              }`}
              onFocus={() => setPhoneValid(true)}
              onBlur={(e) => {
                setPhoneValid(isValidInput(e.target.value, "phone"));
              }}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <textarea
              autoComplete="message"
              name="message"
              id="message"
              cols="30"
              rows="10"
              placeholder="Message"
              className={`${classes.full} ${
                messageValid === false && classes.invalidInput
              }`}
              onFocus={() => setMessageValid(true)}
              onBlur={(e) => {
                setMessageValid(isValidInput(e.target.value, "text"));
              }}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
          </form>
        </div>
        <br />
        {emailSentMessage && <h3>{emailSentMessage}</h3>}
        <br />
        <div className={classes.centerAlign}>
          <Button
            backgroundColor={"#FFA559"}
            shadowColor={"#454545"}
            title={"Submit"}
            onClick={() => {
              submitHandler();
            }}
          />
        </div>
        <br />
        <br />
        <div className={classes.contactInfo}>
          <h2>711 N. Presidents St.</h2>
          <h2>Jackson, MS 39202</h2>
          <h2>601-948-5495</h2>
          <br />
          <br />
          <h2>Executive Director</h2>
          <h2>Colton Robison</h2>
          <a href="mailto:">colton@asphaltpavems.com</a>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Contact;
