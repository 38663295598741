import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AffiliateContextProvider } from "./store/AffiliateContext";
import { EventContextProvider } from "./store/EventContext";
import { CheckoutContextProvider } from "./store/CheckoutContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CheckoutContextProvider>
      <EventContextProvider>
        <AffiliateContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AffiliateContextProvider>
      </EventContextProvider>
    </CheckoutContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
