import { useContext } from "react";

import classes from "./Events.module.css";

// import heroImage from "./assets/event-image-mapa.png";

import EventContext from "../store/EventContext";
import PageTitleBar from "../components/PageTitleBar";
import CardMajor from "../components/CardMajor";

const Events = () => {
  const eventCtx = useContext(EventContext);
  return (
    <div>
      <PageTitleBar title="EVENTS" />
      <div className={`${classes.center} ${classes.background}`}>
        <div className={classes.grid}>
          {eventCtx.events &&
            eventCtx.events.map((event) => (
              <CardMajor
                imageFile={event.event_image_filename}
                imageBucket="mapa_event_images"
                lineOne={event.event_date}
                lineTwo={event.event_time}
                lineThree={event.event_location}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
