import { useState, useEffect } from "react";
import axios from "axios";

import { IconContext } from "react-icons";
import { PiFilePdfDuotone } from "react-icons/pi";

import classes from "./NoticeToContractors.module.css";

import PageTitleBar from "../components/PageTitleBar";

import { siteIP } from "../util/backendUtilities";

const NoticeToContractors = () => {
  const [prevNotices, setPrevNotices] = useState([]);
  const [currentNotices, setCurrentNotices] = useState([]);

  useEffect(() => {
    async function getNotices() {
      let response = await axios.get(`${siteIP}/get-notices`);
      response = await response.data;
      let curr = [];
      let prev = [];
      response.forEach((notice) => {
        if (notice.isCurrent) {
          curr.push(notice);
        } else {
          prev.push(notice);
        }
      });
      setPrevNotices(prev);
      setCurrentNotices(curr);
    }

    getNotices();
  }, []);

  console.log(currentNotices);
  return (
    <div>
      <PageTitleBar title="NOTICE TO CONTRACTORS" />
      <div className={classes.page}>
        <div className={classes.currentNotices}>
          <h1>CURRENT NOTICES:</h1>
          <div
            className={`${classes.flex} ${classes.center} ${classes.column}`}
          >
            {currentNotices &&
              currentNotices.map((notice) => (
                <div
                  key={notice.id}
                  className={`${classes.flex} ${classes.center}`}
                >
                  <IconContext.Provider value={{ size: "5%" }}>
                    <PiFilePdfDuotone />
                  </IconContext.Provider>
                  <a href={notice.url} target="about_blank" rel="noopener">
                    {notice.filename}
                  </a>
                </div>
              ))}
          </div>
        </div>
        <div
          className={`${classes.flex} ${classes.column} ${classes.prevNotices}`}
        >
          <h1>PREVIOUS NOTICES:</h1>
          {prevNotices &&
            prevNotices.map((notice) => (
              <div
                key={notice.id}
                className={`${classes.flex} ${classes.center}`}
              >
                <IconContext.Provider value={{ size: "5%" }}>
                  <PiFilePdfDuotone />
                </IconContext.Provider>
                <a href={notice.url} target="about_blank" rel="noopener">
                  {notice.filename}
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NoticeToContractors;
