export const siteIP = "https://asphaltpavems.com:4000"
export const supaBaseRefId = "yplstzafkrlvgcnssbfi"
export const supaBaseURL = "https://yplstzafkrlvgcnssbfi.supabase.co";
export const supaBaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlwbHN0emFma3JsdmdjbnNzYmZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTgzMzgwNDksImV4cCI6MjAxMzkxNDA0OX0.An8Ra1-0Ddf-hgOF1uXg-SsdL826m4kgP8M4enxWhvM"
export const emailService = "service_s5vs22a"
export const emailJSPubKey = "vGlUNrys7yZRY7py5"
export const payConfirmEmailTemplate = "template_4a55iln"
export const contactFormEmailTemplate = "template_othfon1"
export const memberApplicationConfirmTemplate = "template_s7fl9ab"
export const memberApplicationNotificaitonTemplate = "template_335jt6c"

export const stripePubKey = "pk_live_M8ES84zSQ7pcHzQeuIdSGDlA00JbTag4KC"
