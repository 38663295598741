import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { siteIP } from "../util/backendUtilities";

const EventContext = createContext({
  isLoading: false, 
  setIsLoading: () => {},
  events: [],
  setEvents: () => {},
});

export const EventContextProvider = (props) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] =useState()


  useEffect(() => {
    setIsLoading(true)
    const date = new Date();
        axios.get(`${siteIP}/get-events:${date}`).then((res) => {
          setEvents(res.data);
          setIsLoading(false)
        });
  }, [])

  const contextValue = {
    isLoading,
    setIsLoading,
    events,
    setEvents
  }

  return (
    <EventContext.Provider value={contextValue}>
      {props.children}
    </EventContext.Provider>
  );
};

export default EventContext;
