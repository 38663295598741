import PageTitleBar from "../components/PageTitleBar";
import Button from "../components/Button";
import classes from "./Specifications.module.css";

import heroImg from "./assets/specifications-hero-img.jpg";

const Specifications = () => {
  const asphaltPavingGuideURL =
    "https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/specifications_pdfs/asphalt_paving_guide.pdf";
  const MDOTspecsURL =
    "https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/specifications_pdfs/2017_mdot_specs.pdf";
  const MDOTsectionURL =
    "https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/specifications_pdfs/mdot_section_401.pdf";
  const parkingLotChecklistURL =
    "https://yplstzafkrlvgcnssbfi.supabase.co/storage/v1/object/public/specifications_pdfs/parking_checklist.pdf";

  return (
    <div className={classes.center}>
      <PageTitleBar title={"SPECIFICATIONS"} />
      <div className={classes.heroImage}>
        <img src={heroImg} alt="" />
      </div>
      <div className={classes.buttonGrid}>
        <Button
          backgroundColor={"#FFA559"}
          shadowColor={"#454545"}
          title={"Asphalt Paving Guide"}
          onClick={() => window.open(asphaltPavingGuideURL)}
        />
        <Button
          backgroundColor={"#FFA559"}
          shadowColor={"#454545"}
          title={"2017 MDOT Specs"}
          onClick={() => window.open(MDOTspecsURL)}
        />
        <Button
          backgroundColor={"#FFA559"}
          shadowColor={"#454545"}
          title={"MDOT Section 401"}
          onClick={() => window.open(MDOTsectionURL)}
        />
        <Button
          backgroundColor={"#FFA559"}
          shadowColor={"#454545"}
          title={"Parking Lot Checklist"} 
          onClick={() => window.open(parkingLotChecklistURL)}
        />
      </div>
    </div>
  );
};

export default Specifications;
