import { useRef, useState } from "react";

import classes from "./Membership.module.css";

import PageTitleBar from "../components/PageTitleBar";
import Button from "../components/Button";

import heroImage from "./assets/member-img.jpg";
import { useNavigate } from "react-router-dom";

const Membership = () => {
  const navigate = useNavigate()
  const [selMemberContent, setSelMemberContent] = useState(
    memberContent.contractor
  );
  const scrollRef = useRef("scrollRef");

  return (
    <div>
      <PageTitleBar title="BECOME A MEMBER" />
      <div className={classes.heroImg}>
        <img src={heroImage} alt="" />
      </div>
      <div className={`${classes.mission} ${classes.bgLinear}`}>
        <h2>
          Thank you for your interest in the Mississippi Asphalt Pavement
          Association, Inc.
        </h2>
        <h3>The purpose of this Association is to:</h3>
        <ul>
          <li>
            Improve communications and understanding within the entire asphalt
            paving industry.
          </li>
          <li>
            Influence fair and equitable design, specifications and inspection.
          </li>
          <li>Protect the doctrine of free enterprise and competition.</li>
          <li>
            Stimulate and encourage more extensive research relative to the
            manufacture and use of asphalt pavements.
          </li>
          <li>
            Educate within the industry for higher standards, lawful trade
            practices and lawful ethical conduct.
          </li>
          <li>
            Act and function as an educational Association on behalf of the
            asphalt paving industry.
          </li>
          <li>
            Do any and all things lawful that may be of benefit to members of
            the Association or other business interests and for improving the
            physical, mental and moral condition of mankind.
          </li>
        </ul>
      </div>
      <div
        className={`${classes.bgLinearRev} ${classes.selMemberDescription}`}
        ref={scrollRef}
      >
        <div className={classes.btnGrid}>
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#000000"}
            title={"Contractor Membership"}
            onClick={() => {
              scrollRef.current.scrollIntoView();
              setSelMemberContent(memberContent.contractor);
            }}
          />
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#000000"}
            title={"Sustaining Membership"}
            onClick={() => {
              scrollRef.current.scrollIntoView();
              setSelMemberContent(memberContent.sustaining);
            }}
          />
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#000000"}
            title={"Associate Contractor Membership"}
            onClick={() => {
              scrollRef.current.scrollIntoView();
              setSelMemberContent(memberContent.associateContractor);
            }}
          />
          <Button
            backgroundColor={"#ffa559"}
            shadowColor={"#000000"}
            title={"Associate Membership"}
            onClick={() => {
              scrollRef.current.scrollIntoView();
              setSelMemberContent(memberContent.associate);
            }}
          />
        </div>
        <div className={classes.SMDContent}>{selMemberContent}</div>
        <br />
        <br />
        <Button
          backgroundColor={"#ffa559"}
          shadowColor={"#000000"}
          title={"Apply Now!"}
          onClick={() => {
            navigate('/member-application')
          }}
        />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Membership;

const memberContent = {
  contractor: (
    <div>
      <h1>CONTRACTOR MEMEBERSHIP</h1>
      <p>
        Limited to those who are actively engaged in the manufacturing or
        production, with the use of an asphalt plant, or scientifically
        proportioned asphalt paving mixture Each Contractor Member shall be
        entitled to one vote and can serve on the MAPA Board of Directors. Dues
        for contractor members are outlined on the membership application.
      </p>
      
    </div>
  ),
  sustaining: (
    <div>
      <h1>SUSTAINING MEMBERSHIP</h1>
      <p>
        Limited to those who are actively engaged in the manufacturing,
        furnishing, or sales of bitumen used directly in the manufacturing,
        production, or construction of Hot Mix Asphalt Concrete Each Sustaining
        Member shall be entitled to one vote. No sustaining member may hold any
        elective office in the Association. The dues of Sustaining Members shall
        be Six Thousand Dollars ($6,000.00) annually, due and payable in
        advance.
      </p>
    </div>
  ),
  associateContractor: (
    <div>
      <h1>ASSOCIATE CONTRACTOR MEMBERSHIP</h1>
      <p>
        Limited to those actively engaged in the construction or maintenance of
        flexible pavements, does not own and operate an asphalt plant and does
        lay-down of hot-mix asphalt using a power spreader and roller Associate
        Contractor Members are entitled to one collective vote on the Board of
        Directors. Said voting representative shall be elected by a majority
        vote of the “Associate Contractor Members.” The dues of Associate
        Contractor Members shall be One Thousand Seven Hundred Fifty Dollars
        ($1,750.00) annually, due and payable in advance for the first year’s
        dues; thereafter, the dues may be paid yearly, semi-annual, or
        quarterly.
      </p>
    </div>
  ),
  associate: (
    <div>
      <h1>ASSOCIATE MEMBERSHIP</h1>
      <p>
        Limited to those who are actively engaged in the furnishing or sales of
        materials and/or equipment used directly in the manufacturing,
        production, or construction of asphalt paving mixture This includes
        supplying various materials used in construction of projects to be paved
        with hot mix asphalt, along with those interested and engaged in
        financing, insuring and bonding asphalt paving production and work; and
        those engaged in paint striping asphalt paving. Associate members may
        attend meetings of members but shall have no vote in the affairs of the
        Association nor hold any office in the Association. The dues of
        Associate Members, fixed by the Board of Directors, are One Thousand
        Dollars ($1,000.00) annually, due and payable in advance.
      </p>
    </div>
  ),
};
