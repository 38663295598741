import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavMenu.module.css";
import { useMediaQuery } from "react-responsive";

const NavMenu = (props) => {
  const { onClickOutside } = props;
  const ref = useRef(null);
  const isMobile = useMediaQuery({ query: `(max-width: 700px)` });
  const [width, setWidth] = useState("0");
  const [textPosition, setTextPosition] = useState('-500px');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });

  useEffect(() => {
    setTimeout(() => {
      if (!isMobile) {
        setWidth("275px");
      } else {
        setWidth("175px");
      }
      setTextPosition("0px")
    }, 0);
  });

  return (
    <div ref={ref} className={classes.menu} style={{ width: `${width}` }}>
      <div className={classes.navList} style={{left: `${textPosition}`}}>
        {isMobile && (
          <>
            <NavLink to={"/"} className={classes.nav} onClick={onClickOutside}>
              HOME
            </NavLink>
            <NavLink
              to={"/about"}
              className={classes.nav}
              onClick={onClickOutside}
            >
              ABOUT
            </NavLink>
            <NavLink
              to={"/contact"}
              className={classes.nav}
              onClick={onClickOutside}
            >
              CONTACT
            </NavLink>
            {/* <NavLink
              to={"/news"}
              className={classes.nav}
              onClick={onClickOutside}
            >
              NEWS
            </NavLink> */}
          </>
        )}
        <NavLink to={"/specs"} className={classes.nav} onClick={onClickOutside}>
          SPECIFICATIONS
        </NavLink>
        {/* <NavLink
          to={"/events"}
          className={classes.nav}
          onClick={onClickOutside}
        >
          EVENTS
        </NavLink> */}
        <NavLink
          to={"/affiliates"}
          className={classes.nav}
          onClick={onClickOutside}
        >
          MEMBERS
        </NavLink>
        <NavLink
          to={"/contracts-bidding"}
          className={classes.nav}
          onClick={onClickOutside}
        >
          CONTRACTS & BIDDING
        </NavLink>
        <NavLink
          to={"certs-training"}
          className={classes.nav}
          onClick={onClickOutside}
        >
          CERTIFICATIONS & TRAINING
        </NavLink>
        <NavLink
          to={"membership"}
          className={classes.nav}
          onClick={onClickOutside}
        >
          MEMBERSHIP
        </NavLink>
        {isMobile && (
          <NavLink
            to={"/checkout"}
            className={classes.nav}
            onClick={onClickOutside}
          >
            PAY
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default NavMenu;
