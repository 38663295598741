export const isValidInput = (value, testType) => {
  // console.log(value, testType)
  switch (testType) {
    case "text":
      if (value === "" || value === undefined) {
        return false;
      } else {
        return true;
      }
    case "email":
      if (
        !Array.from(value).includes("@.") &&
        !Array.from(value).includes(".")
      ) {
        return false;
      } else {
        return true;
      }
    case "zip":
      if (Array.from(value).length !== 5) {
        return false;
      } else {
        return true;
      }
    case "phone":
      const arr = Array.from(value);
      let isValid = true;
      arr.forEach((v) => {
        if (
          ![
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            "(",
            ")",
            ".",
          ].includes(v)
        ) {
          isValid = false;
        }
        const phoneNumber = arr.filter((n) =>
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(n))
        );
        if (phoneNumber.length !== 10) {
          isValid = false;
        }
      });
      return isValid;
    default:
      break;
  }
};

export const checkAutoFill = (inputArr, inputFuncArr) => {
  //* This does not work yet.
  console.log(inputArr)
  inputArr.forEach((input, index) => {
    if (input !== "") {
      inputFuncArr[index](true)
    }
  })
}

export const isFormValid = (inputArr, inputFuncArr) => {
  let isValid = true
  inputArr.forEach((input, index) => {
    if(!input) {
      inputFuncArr[index](false)
     isValid = false
    } 
  })
  return isValid
};

export const price = (isReg, classSel) => {
  if (isReg) {
    switch (+classSel) {
      case 1:
        return { member: "$725", non: "$1,050" };
      case 2:
        return { member: "$830", non: "$1,145" };
      case 3:
        return { member: "$860", non: "$1,200" };
      case 4:
        return { member: "$250", non: "$250" };
      default:
        return { member: "--", non: "--" };
    }
  } else {
    switch (+classSel) {
      case 1:
        return { member: "$1,690", non: "$2,035" };
      case 2:
        return { member: "$1,820", non: "$2,170" };
      case 3:
        return { member: "$2,725", non: "$3,085" };
      case 4:
        return { member: "$250", non: "$250" };
      default:
        return { member: "--", non: "--" };
    }
  }
};


