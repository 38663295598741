import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { siteIP } from "../util/backendUtilities";

const AffiliateContext = createContext({
  affiliates: [],
  setAffiliates: () => {},
});

export const AffiliateContextProvider = (props) => {
  const [affiliates, setAffiliates] = useState([]);

  useEffect(() => {
    axios.get(`${siteIP}/get-affiliates`).then((res) => {
            setAffiliates(res.data);
          });
        
  }, [])

  const contextValue = {
    affiliates,
    setAffiliates,
  };

  return (
    <AffiliateContext.Provider value={contextValue}>
      {props.children}
    </AffiliateContext.Provider>
  );
};

export default AffiliateContext;
